import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { EntityType } from '../../../dataStore';
import { useStyles } from './search.jss';

export interface EntitySwitchProps {
  value: EntityType;
  onChange(value: EntityType): void;
}
export const EntitySwitch = React.memo((props: EntitySwitchProps) => {
  const [t] = useTranslation();
  const classes = useStyles(); 

  return (
    <RadioGroup
      value={props.value.toString()}
      onChange={(e, v) => {
        props.onChange(v as EntityType);
      }}
      row={true}
      classes={{ root: classes.entitySwitch }}
    >
      <FormControlLabel
        value={EntityType.NCompanySearchResult}
        control={<Radio />}
        label={t("newnity.company")}
      />
      <FormControlLabel
        value={EntityType.NLocationSearchResult}
        control={<Radio />}
        label={t("newnity.location")}
      />
      <FormControlLabel
        value={EntityType.NDeviceSearchResult}
        control={<Radio />}
        label={t("newnity.device")}
      />
    </RadioGroup>
  );
});