import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextFastFieldWrapper } from 'app/components/forms';
import { IdName } from '../../../../dataStore/types';
import { DeviceSearchFields } from '../../../duck/types';
import { ContentWrapper } from './contentWrapper';

export interface DeviceTabProps {
  handleChange(e: React.ChangeEvent): void;
  companies: IdName[];
}

export const DeviceTab = (props: DeviceSearchFields & DeviceTabProps) => {
  const [t] = useTranslation();
  return (
    <ContentWrapper>
      <TextFastFieldWrapper
        name="device.deviceNumber"
        value={props.deviceNumber}
        onChange={props.handleChange}
        label={t('newnity.search.deviceNumber')}
      />
      <TextFastFieldWrapper
        name="device.deviceDescription"
        value={props.deviceDescription}
        onChange={props.handleChange}
        label={t('newnity.search.deviceDescription')}
      />
      <TextFastFieldWrapper
        name="device.serial"
        value={props.serial}
        onChange={props.handleChange}
        label={t('newnity.search.serial')}
      />
      <TextFastFieldWrapper
        name="device.locationNumber"
        value={props.locationNumber}
        onChange={props.handleChange}
        label={t('newnity.search.locationNumber')}
      />
      <TextFastFieldWrapper
        name="device.locationName"
        style={{ fontSize: '12px' }}
        value={props.locationName}
        onChange={props.handleChange}
        label={t('newnity.search.locationName')}
      />
      <TextFastFieldWrapper
        name="device.siteNumber"
        value={props.siteNumber}
        onChange={props.handleChange}
        label={t('newnity.search.siteNumber')}
      />
      <TextFastFieldWrapper
        name="device.company"
        defaultValue={props.company}
        onChange={props.handleChange}
        label={t('newnity.search.company')}
      />
      <TextFastFieldWrapper
        name="device.salesOrder"
        value={props.salesOrder}
        onChange={props.handleChange}
        label={t('newnity.search.salesOrder')}
      />
    </ContentWrapper>
  );
};
