import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Tab, Tabs } from '@material-ui/core';

import { EntityType, IdName } from '../../../../dataStore';
import { SearchFields } from '../../../duck/types';
import { AddressTab } from './addressTab';
import { CompanyTab } from './companyTab';
import { DeviceTab } from './deviceTab';
import { LocationTab } from './locationTab';
import { PhoneTab } from './phoneTab';
import { useStyles } from './tabs.jss';

export interface TabsProps {
  entity: EntityType;
  fields: SearchFields;
  handleChange(e: React.ChangeEvent): void;
  onTabChange(index: number): void;
  tabIndex: number;
  companies: IdName[];
}

const TabLabel = (props: { label: string; notEmptyFields: number }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.notEmptyFields ? (
        <Badge
          badgeContent={props.notEmptyFields}
          color="secondary"
          classes={{ badge: classes.badge }}
        >
          {props.label}
        </Badge>
      ) : (
        props.label
      )}
    </React.Fragment>
  );
};

const countNotEmpty = (...args: string[]): number => {
  return args.filter(a => a !== '').length;
};

const TabsComponent = React.memo((props: TabsProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const tabs = () => {
    const { company, location, device } = props.fields;

    switch (props.entity) {
      case EntityType.NCompanySearchResult:
        return [
          <Tab
            key="1.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('1.company')}
                notEmptyFields={countNotEmpty(company.name, company.number)}
              />
            }
          />,
          <Tab
            key="1.2"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.search.tab.company.address')}
                notEmptyFields={countNotEmpty(
                  company.address,
                  company.city,
                  company.state,
                  company.zip,
                  company.country
                )}
              />
            }
          />,
          <Tab
            key="1.3"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabLastSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.phone')}
                notEmptyFields={countNotEmpty(
                  company.firstName,
                  company.lastName,
                  company.phone,
                  company.email
                )}
              />
            }
          />,
        ];
      case EntityType.NLocationSearchResult:
        return [
          <Tab
            key="2.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.location')}
                notEmptyFields={countNotEmpty(
                  location.locationNumber,
                  location.locationName,
                  location.deviceNumber,
                  location.serial,
                  location.siteNumber,
                  location.company
                )}
              />
            }
          />,
          <Tab
            key="2.2"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabLastSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.search.locationAddress')}
                notEmptyFields={countNotEmpty(
                  location.address,
                  location.city,
                  location.state,
                  location.zip,
                  location.country
                )}
              />
            }
          />,
        ];
      case EntityType.NDeviceSearchResult:
        return [
          <Tab
            key="3.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.device')}
                notEmptyFields={countNotEmpty(
                  device.deviceNumber,
                  device.deviceDescription,
                  device.serial,
                  device.locationNumber,
                  device.locationName,
                  device.siteNumber,
                  device.company,
                  device.salesOrder
                )}
              />
            }
          />,
          <Tab
            key="3.2"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabLastSelected,
              wrapper: classes.tabWrapper,
            }}
            label={
              <TabLabel
                label={t('newnity.search.locationAddress')}
                notEmptyFields={countNotEmpty(
                  location.address,
                  location.city,
                  location.state,
                  location.zip,
                  location.country
                )}
              />
            }
          />,
        ];
    }
  };

  const tabContent = () => {
    switch (props.entity) {
      case EntityType.NCompanySearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <CompanyTab {...props.fields.company} handleChange={props.handleChange} />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                parentName="company"
                {...props.fields.company}
                handleChange={props.handleChange}
                key="1"
              />
            )}
            {props.tabIndex === 2 && (
              <PhoneTab {...props.fields.company} handleChange={props.handleChange} />
            )}
          </React.Fragment>
        );
      case EntityType.NLocationSearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <LocationTab
                {...props.fields.location}
                handleChange={props.handleChange}
                companies={props.companies}
              />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                parentName="location"
                {...props.fields.location}
                handleChange={props.handleChange}
                key="2"
              />
            )}
          </React.Fragment>
        );
      case EntityType.NDeviceSearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <DeviceTab
                {...props.fields.device}
                handleChange={props.handleChange}
                companies={props.companies}
              />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                parentName="device"
                {...props.fields.location}
                handleChange={props.handleChange}
                key="3"
              />
            )}
          </React.Fragment>
        );
      default:
        return <div />;
    }
  };

  return (
    <React.Fragment>
      <Tabs
        value={props.tabIndex}
        onChange={(e, v) => props.onTabChange(v)}
        textColor="primary"
        variant="fullWidth"
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {tabs()}
      </Tabs>
      {tabContent()}
    </React.Fragment>
  );
});

export { TabsComponent as Tabs };
