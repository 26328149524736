import { FastField, FieldProps } from 'formik';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import { Autocomplete, GroupBox, MaxLengthTextField as TextField } from '../../../../components';
import { IdName, NLocation } from '../../../../dataStore';
import { countries } from '../../../../dataStore/countries';
import { timezones } from '../../../../dataStore/timezones';
import { CountryInfo } from '../../../../dataStore/types';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { useStyles } from './locationDetails.jss';

export interface LocationDetailsProps {
  openDeviceList: (props: any) => void;
  locationId: number;
}

const fieldLabel = (field: { name: string }, t: i18next.TFunction) =>
  t(`newnity.edit.location.tab.details.${field.name}`);

export const LocationDetails: React.FunctionComponent<LocationDetailsProps> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      <GroupBox title="Location">
        <FastField
          name="oracleNumber"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              fullWidth
              margin="normal"
              error={validateEmptyField(form, field)}
              helperText={helperText(form, field)}
              inputProps={{ maxLength: 50 }}
            />
          )}
        />
        <FastField
          name="name"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              margin="normal"
              fullWidth
              error={validateEmptyField(form, field)}
              helperText={helperText(form, field)}
              autoComplete="something"
            />
          )}
        />
        <FastField
          name="clientSiteId"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              margin="normal"
              fullWidth
              helperText={helperText(form, field)}
              inputProps={{ maxLength: 64 }}
            />
          )}
        />
      </GroupBox>
      <GroupBox title="Address">
        <FastField
          name="timezone"
          render={({ field, form }: FieldProps<NLocation>) => (
            <Autocomplete
              items={timezones}
              compareMethod="contains"
              selectedItem={form.values.timezone}
              minCharInput={4}
              textFieldProps={{
                name: 'something',
                label: fieldLabel(field, t),
                error: validateEmptyField(form, field),
                helperText: helperText(form, field),
              }}
              onChange={(selectedItem: IdName | undefined) =>
                form.setFieldValue('timezone', selectedItem ? selectedItem.name : '')
              }
            />
          )}
        />
        <FastField
          name="country"
          render={({ field, form }: FieldProps<NLocation>) => (
            <Autocomplete
              items={countries}
              selectedItem={form.values.country}
              minCharInput={2}
              textFieldProps={{
                name: 'something',
                label: fieldLabel(field, t),
                error: validateEmptyField(form, field),
                helperText: helperText(form, field),
              }}
              onChange={(selectedItem: CountryInfo | undefined) =>
                form.setFieldValue('country', selectedItem ? selectedItem.name : '')
              }
            />
          )}
        />
        <FastField
          name="city"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              fullWidth
              margin="normal"
              error={validateEmptyField(form, field)}
              helperText={helperText(form, field)}
              autoComplete="something"
            />
          )}
        />
        <FastField
          name="state"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              fullWidth
              margin="normal"
              error={validateEmptyField(form, field)}
              helperText={helperText(form, field)}
              autoComplete="something"
            />
          )}
        />
        <FastField
          name="zipCode"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              fullWidth
              margin="normal"
              autoComplete="something"
              inputProps={{ maxLength: 50 }}
            />
          )}
        />
        <FastField
          name="address"
          render={({ field, form }: FieldProps<NLocation>) => (
            <TextField
              label={fieldLabel(field, t)}
              {...field}
              fullWidth
              margin="normal"
              autoComplete="something"
              multiline
            />
          )}
        />
      </GroupBox>
      <Button
        variant="contained"
        className={classes.devicesButton}
        onClick={() => props.openDeviceList({ openedFromLocation: props.locationId })}
      >
        {' '}
        {t('newnity.edit.location.tab.details.devices')}
      </Button>
    </>
  );
};
