import { ICellRendererParams } from '@ag-grid-community/core';
import { useBladeClosing } from 'app/blade';
import { BladeProps } from 'app/blade/duck/types';
import {
  ButtonsHeaderAndContent,
  createBtn,
} from 'app/components/bladeLayout/buttonsHeaderAndContent';
import { FooterSubmit } from 'app/components/bladeLayout/footerSubmit';
import { DataGridContainer } from 'app/components/dataGrid';
import { ColumnSettings, DataGridSelectionType } from 'app/components/dataGrid/agDataGrid';
import { EntityType } from 'app/dataStore';
import { IdName, User } from 'app/dataStore/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../localization/i18n';
import { useStyles } from './addWorkgroupUsers.jss';

export interface AddWorkgroupUsersProps {
  workgroupId: number;
  users: User[];
  workgroupUsers: User[];
  isLoading: boolean;
  isSaving: boolean;
  saved: boolean;
}

export interface AddWorkgroupUsersActions {
  fetchUsers: () => void;
  save: (workgroupId: number, userIds: number[]) => void;
  setSaved: (value: boolean) => void;
  openNewUser: () => void;
  forceCloseBlade: () => void;
  openCreateUserBlade: () => void;
}

type Props = AddWorkgroupUsersProps & AddWorkgroupUsersActions & BladeProps;

const allColumns: ColumnSettings[] = [
  { name: 'id', width: 140 },
  {
    name: 'login',
    width: 140,
    sort: {
      direction: 'asc',
      order: 0,
    },
    lockVisibility: true,
  },
  { name: 'email', width: 170 },
  { name: 'firstName', width: 150 },
  { name: 'lastName', width: 150 },
  { name: 'passwordExpDate', width: 170 },
  {
    name: 'lockLevel',
    width: 140,
    filter: 'agSetColumnFilter',
    formatter: (item: ICellRendererParams) => {
      const data = item.data as User;
      let cellValue = '';
      switch (data.lockLevel) {
        case 0:
          cellValue = i18n.t('workgroup.users.isLocked.unlocked');
          break;
        case 1:
          cellValue = i18n.t('workgroup.users.isLocked.locked');
          break;
        case 2:
          cellValue = i18n.t('workgroup.users.isLocked.masterlocked');
          break;
        case 3:
          cellValue = i18n.t('workgroup.users.isLocked.inactivity');
          break;
      }
      return <div>{cellValue}</div>;
    },
    keyCreator: (params: any) => {
      switch (params.value) {
        case 0:
          return i18n.t('workgroup.users.isLocked.unlocked');
        case 1:
          return i18n.t('workgroup.users.isLocked.locked');
        case 2:
          return i18n.t('workgroup.users.isLocked.masterlocked');
        case 3:
          return i18n.t('workgroup.users.isLocked.inactivity');
      }
      return 'Not Set';
    },
  },
  {
    name: 'lockReason',
    width: 150,
  },
];

export const AddWorkgroupUsers: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([] as IdName[]);
  const { fetchUsers, setDirty, setSaved } = props;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (props.saved) {
      setDirty(false);
      setSaved(false);
    }
  }, [props.saved, setDirty, setSaved]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  const handleSelection = useCallback(
    us => {
      const users: User[] = us.filter((u: any) => !u._default);
      setSelectedUsers(users);

      const isDirty =
        users.length !== props.workgroupUsers.length ||
        users.filter(u => props.workgroupUsers.find(e => e.id === u.id)).length !==
          props.workgroupUsers.length;

      if (!props.isDirty && isDirty) {
        setDirty(true);
      }

      if (props.isDirty && !isDirty) {
        setDirty(false);
      }
    },
    [props.workgroupUsers, props.isDirty, setDirty]
  );
  const buttons = [createBtn(props.openCreateUserBlade)];

  const footer = () => {
    return (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={props.forceCloseBlade}
        submitButtonLabel={t('footer.save')}
        submit={() =>
          props.save(
            props.workgroupId,
            selectedUsers.filter(u => !props.workgroupUsers.find(e => e.id === u.id)).map(u => u.id)
          )
        }
        submitInProgress={props.isSaving}
      />
    );
  };

  return (
    <ButtonsHeaderAndContent
      footer={footer}
      buttons={buttons}
      isBusy={props.isLoading}
      removePadding
      removeVerticalScroll
    >
      <div className={classes.container}>
        <DataGridContainer
          items={props.users}
          highlightNewlyAdded
          keepDefaultSelection={true}
          defaultSelection={props.workgroupUsers}
          onSelect={handleSelection}
          entityType={EntityType.User}
          selectionType={DataGridSelectionType.Multiple}
          columnSettings={allColumns}
          supressUnselectDefault
        />
      </div>
    </ButtonsHeaderAndContent>
  );
};
