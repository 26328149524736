import { debounce } from 'debounce';
import { Form, Formik, FormikActions, FormikErrors } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BladeProps, useBladeClosing } from '../../../../blade';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { TabsHeaderAndContent } from '../../../../components/bladeLayout/tabsHeaderAndContent';
import { validateDeviceSettings } from '../../../../components/deviceSettings/deviceSettings';
import { DeviceSettingsContainer } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { EntityType, NLocation } from '../../../../dataStore';
import { LocationDetails } from './locationDetails';
import { useStyles } from './locationEdit.jss';

export interface LocationEditProps {
  location: NLocation;
  companyId: number;
  isSaving: boolean;
}

export interface LocationEditActions {
  configureWiFiSettings: () => void;
  createLocation: (companyId: number, location: NLocation) => void;
  fetchLocation: (id: number) => void;
  closeBlade: () => void;
  openDeviceList: (props: any) => void;
}

type Props = LocationEditProps & LocationEditActions & BladeProps;

export const LocationEdit: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const createMode = props.location.id === 0;

  const { fetchLocation } = props;

  useEffect(() => {
    if (props.location.id) {
      fetchLocation(props.location.id);
    }
  }, [fetchLocation, props.location.id]);

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.closeBlade()
  );

  const tabs = useMemo(
    () => [
      { label: t('newnity.edit.location.tab.details') },
      { label: t('device.settings.title') },
    ],
    [t]
  );
  const content = useCallback(
    index =>
      index === 0 ? (
        <LocationDetails openDeviceList={props.openDeviceList} locationId={props.location.id} />
      ) : (
        <DeviceSettingsContainer
          entityType={EntityType.NLocation}
          entityId={props.location.id}
          parentEntityId={props.companyId}
          isSaving={props.isSaving}
        />
      ),
    [props.location, props.openDeviceList, props.companyId, props.isSaving]
  );
  const footer = useCallback(
    () => (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={props.closeBlade}
        submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
        submitInProgress={props.isSaving}
      />
    ),
    [props.closeBlade, props.isSaving, createMode, t]
  );
  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values: NLocation) => {
        let errors: FormikErrors<NLocation> = {};
        errors = checkRequiredField(errors, 'name', values.name, t);
        errors = checkRequiredField(errors, 'oracleNumber', values.oracleNumber, t);
        errors = checkRequiredField(errors, 'country', values.country, t);
        errors = checkRequiredField(errors, 'timezone', values.timezone, t);
        errors = checkRequiredField(errors, 'city', values.city, t);
        errors = checkRequiredField(errors, 'state', values.state, t);
        validateDeviceSettings(values, errors);
        return errors;
      }}
      initialValues={{ ...props.location }}
      onSubmit={(values: NLocation, actions: FormikActions<NLocation>) => {
        props.setDirty(false);
        props.createLocation(props.companyId, values);
      }}
      render={() => (
        <Form
          autoComplete="off"
          className={classes.container}
          onChange={props.isDirty ? undefined : debounce(() => props.setDirty(true), 250)}
        >
          <TabsHeaderAndContent
            disabled={props.isSaving}
            tabs={tabs}
            content={content}
            footer={footer}
          />
        </Form>
      )}
    />
  );
};

const checkRequiredField = (
  errors: FormikErrors<NLocation>,
  name: keyof FormikErrors<NLocation>,
  value: string | undefined,
  t: i18next.TFunction
) => {
  if (!value) {
    errors[name] = t('newnity.company.field.error');
  }
  return errors;
};
