import { WifiSecurity } from '../newnity/duck/types';

export const areaName = 'dataStore';

export interface IdName {
  id: number;
  name: string;
}

export interface Player extends IdName {
  serial: string;
  siteId: number;
  workgroupId: number;
  stationId: number;
  stationName: string;
}

export enum PlayerType {
  Unknown = 0,
  Windows = 1,
  Android = 2,
  Brightsign = 3,
  SamsungSSP = 4,
}

export interface Site extends IdName {
  workgroupId: number;
  oracleNumber: string;
  clientSiteId: string;
  timezone: string;
  country: string;
  city: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  address: string;
}

export interface Stream extends IdName {
  x: number;
  y: number;
}

export interface Workgroup extends IdName {
  parentId: number;
}

export interface TemplateLibrary extends IdName {}

export interface User extends IdName {
  firstName: string;
  lastName: string;
  email: string;
  login: string;
  password: string;
  accountExpDate?: string;
  passwordExpDate?: string;
  isSaving?: boolean;
  lastWelcomeEmailDate: string;
  lockLevel?: number;
  lockReason?: string;
}

export interface Brand extends IdName {
  logoPath: string;
}

export interface NCompany extends Workgroup {
  number: string;

  // Address fields
  country: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;

  // Contact fields
  firstName: string;
  lastName: string;
  phone: string;
  email: string;

  deviceSettings: DeviceSettings;
}

export interface NLocation extends Site {
  deviceSettings: DeviceSettings;
}

export interface NLocationList extends Site {
  deviceSettings?: DeviceSettings;
}

export interface DeviceSettings {
  entityId: number;
  entityType: EntityType;

  // Audio settings fields
  enableSoundVolumeOverride: boolean;
  audioVolume: string;

  // Network settings fields
  enableNetworkSettingsOverride: boolean;
  enableWifi: boolean;
  enableEthernet: boolean;
  enableProxy: boolean;

  wifiSettings: WifiSettings;
  ethernetSettings: BaseNetworkSettings;
  proxySettings: ProxySettings;
}

export interface NDevice extends IdName {
  deviceTypeId: PlayerType;
  deviceTypeName: string;
  serialNumber: string;
  salesOrderNumber: string;
  description: string;
  locationName: string;
  locationNumber: string;
  locationId: number;
  stationId: number;
  stationName: string;
  deviceSettings: DeviceSettings;
}

export interface NProgram extends IdName {
  genre: string;
  description: string;
  albumArt: string;
  artists: string;
}

export interface NStation extends IdName {
  isDefault: boolean;
  players: Player[];
}

export interface NCompanySearchResult extends IdName {
  name: string;
  number: string;
  city: string;
  country: string;
}

export interface NLocationSearchResult extends IdName {
  locationNumber: string;
  locationName: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}

export interface NDeviceSearchResult extends IdName {
  serial: string;
  salesOrder: string;
  deviceDescription: string;
  locationName: string;
  locationNumber: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}

export interface BaseNetworkSettings {
  id: number;
  networkEnabled: boolean;
  dhcpEnabled: number; // 0 (static) or 1 (dhcp enabled)
  ipAddress: string;
  ipMask: string;
  ipGateway: string;
  dnsIp1: string;
  dnsIp2: string;
}

export interface WifiSettings extends BaseNetworkSettings {
  ssId: string;
  securityKeyType: WifiSecurity;
  securityKey: string;
  verifySecurityKey: string;
}

export type NetworkSettings = BaseNetworkSettings & WifiSettings;

export interface ProxySettings {
  idProxySettings: number;
  proxyEnabled: boolean;
  proxyAddress: string;
  proxyPort: number;
  proxyUser: string;
  proxyPassword: string;
}

export interface HasDeviceSettings extends IdName {
  [key: string]: any;
  deviceSettings: DeviceSettings;
}

export interface NormalizedIdNames<T extends IdName> {
  [id: number]: T;
}

export interface EntityState<T extends IdName> {
  items: NormalizedIdNames<T>;
  itemsFetching: DataItemsFetchStatus;
}

export interface DataItemsFetchStatus {
  isFetching: boolean;
  error: string;
  fetchComplete: boolean;
}

export interface NParentCompanyWorkgroup extends Workgroup {
  deviceSettings: DeviceSettings;
}

export interface ImportSkippedRow {
  id: number;
  row: number;
  columnName: string;
  name: string;
}

export interface ImportResult {
  skipped: ImportSkippedRow[];
  skippedRowCount: number;
  total: number;
  completed: number; 
}

export enum EntityType {
  ImportSkippedRow = 'importSkippedRow',
  Workgroup = 'workgroup',
  Brand = 'brand',
  TemplateLibrary = 'templateLibrary',
  Player = 'player',
  Site = 'site',
  Stream = 'stream',
  Channel = 'channel',
  User = 'user',
  NCompany = 'nCompany',
  NDevice = 'nDevice',
  NLocation = 'nLocation',
  NLocationList = 'nLocationList',
  NCompanySearchResult = 'nCompanySearchResult',
  NLocationSearchResult = 'nLocationSearchResult',
  NDeviceSearchResult = 'nDeviceSearchResult',
  NParentCompanyWorkgroup = 'nParentCompanyWorkgroup',
  HasDeviceSettings = 'hasDeviceSettings',
  NStation = 'nStation',
  NProgram = 'nProgram',
}

export enum EntityWithSettings {
  NCompany = 'nCompany',
  NDevice = 'nDevice',
  NLocation = 'nLocation',
}

export interface CountryInfo extends IdName {
  code: string;
}

export interface DataStoreState {
  [EntityType.Player]: EntityState<Player>;
  [EntityType.Site]: EntityState<Site>;
  [EntityType.Workgroup]: EntityState<Workgroup>;
  [EntityType.Brand]: EntityState<Brand>;
  [EntityType.TemplateLibrary]: EntityState<TemplateLibrary>;
  [EntityType.User]: EntityState<User>;

  [EntityType.NCompany]: EntityState<NCompany>;
  [EntityType.NLocation]: EntityState<NLocation>;
  [EntityType.NDevice]: EntityState<NDevice>;
  [EntityType.NParentCompanyWorkgroup]: EntityState<NParentCompanyWorkgroup>;

  [EntityType.NCompanySearchResult]: EntityState<NCompanySearchResult>;
  [EntityType.NLocationSearchResult]: EntityState<NLocationSearchResult>;
  [EntityType.NDeviceSearchResult]: EntityState<NDeviceSearchResult>;
  [EntityType.HasDeviceSettings]: EntityState<HasDeviceSettings>;
  [EntityType.NStation]: EntityState<NStation>;
  [EntityType.NProgram]: EntityState<NProgram>;
  [EntityType.NLocationList]: EntityState<NLocationList>;
}
