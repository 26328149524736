import i18n from 'i18next';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { BladeActions, BladeConfig, bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { defaultSettings } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { DataStoreSelectors, NLocation } from '../../../../dataStore';
import { setSelectedDevices, locationFinishEdit } from '../../../duck/actions';
import { selectCompanyId, selectSavingLocationData } from '../../../duck/selectors';
import { fetchLocation, saveLocationData } from '../../../duck/thunks';
import { DeviceListBladeType } from '../../device/list/deviceListContainer';
import { LocationEdit, LocationEditActions, LocationEditProps } from './locationEdit';

export interface LocationEditContainerProps extends BladeProps {
  locationSiteId?: number;
  locationName?: string;
}

const getEmptyLocation = (): NLocation => ({
  address: '',
  city: '',
  clientSiteId: '',
  country: '',
  id: 0,
  name: '',
  phoneNumber: '',
  oracleNumber: '',
  state: '',
  timezone: '',
  workgroupId: 0,
  zipCode: '',
  deviceSettings: {
    ...defaultSettings,
    wifiSettings: { ...defaultSettings.wifiSettings },
    ethernetSettings: { ...defaultSettings.ethernetSettings },
    proxySettings: { ...defaultSettings.proxySettings },
  },
});

const getLocation = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyLocation();
  }
  
  let location = 
    DataStoreSelectors.NLocation.selectLocationById(state, id);

  if (location === undefined) {
    location = getEmptyLocation();
    location.id = id;
  }

  return location;
}

const mapStateToProps: MapStateToProps<
  LocationEditProps,
  LocationEditContainerProps,
  any
> = (state, ownProps) => {
  return {
    location: getLocation(state, ownProps.locationSiteId),
    companyId: selectCompanyId(state),
    isSaving: selectSavingLocationData(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  LocationEditActions,
  LocationEditContainerProps
> = (dispatch, ownProps) => {
  return {
    configureWiFiSettings: () =>
      openBlade(LocationEditBladeType, '', {}, dispatch),
    createLocation: (companyId: number, location: NLocation) => {
      dispatch<any>(saveLocationData(companyId, location, bladeId(ownProps)));
    },
    fetchLocation: id => {
      dispatch<any>(fetchLocation(id));
    },
    closeBlade: () => {
      dispatch(BladeActions.forceCloseBlade(bladeId(ownProps)))
      dispatch<any>(locationFinishEdit())
    },
    openDeviceList: props => {
      dispatch(setSelectedDevices([]));
      openBlade(LocationEditBladeType, DeviceListBladeType, props, dispatch);
    },
  };
};

export const LocationEditBladeType = 'NEWNITY_LOCATION_EDIT';

const bladeId = (ownProps: LocationEditContainerProps) =>
  ownProps.locationSiteId
    ? `${LocationEditBladeType}_${ownProps.locationSiteId}`
    : LocationEditBladeType;

const bladeConfig: BladeConfig<LocationEditContainerProps> = {
  size: { defaultWidth: 800, minWidth: 800 },
  bladeType: LocationEditBladeType,
  id: bladeId,
  title: ownProps =>
    ownProps.locationName
      ? ownProps.locationName
      : i18n.t('newnity.edit.location.blade.create.title'),
  allowMultipleInstances: false,
};

export const LocationEditContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(LocationEdit);
