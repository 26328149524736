import { getWelcomeEmailTemplates, userSaving } from 'app/users/duck/selectors';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { selectCompanyById } from 'app/dataStore/selectors/newnityCompanies';
import { selectCurrentCompanyInfo } from 'app/newnity/duck/selectors';
import { BladeActions, bladeConnect, BladeProps } from '../../../../blade';
import { DataStoreSelectors, User } from '../../../../dataStore';
import {
  fetchUser,
  saveUser,
  userEditingFinished,
  fetchWelcomeEmailTemplates,
} from '../../../duck/actions';
import { getEmptyUser, getUserForm, UserDto } from '../../../duck/types';
import { EditUser, EditUserActions, EditUserProps } from './editUser';

interface EditUserContainerProps extends BladeProps {
  userId: number;
  firstName: string;
  lastName: string;
  workgroupId: number;
  workgroupName: string;
  automaticallyAdd: boolean;
}

const getUser = (state: any, id?: number): User => {
  if (!id) {
    return getEmptyUser();
  }

  let user = DataStoreSelectors.User.selectUserById(state, id);

  if (!user) {
    user = getEmptyUser();
    user.id = id;
  }

  return user;
};

const mapStateToProps: MapStateToProps<EditUserProps, EditUserContainerProps, any> = (
  state,
  ownProps
) => {
  const user = getUser(state, ownProps.userId);
  const userForm = getUserForm(user);
  const isFetching = !!ownProps.userId && !user.login;
  const isSaving = userSaving(state);
  const welcomeEmailTemplates = getWelcomeEmailTemplates(state);

  return {
    user: userForm,
    isFetching,
    isSaving,
    welcomeEmailTemplates,
    workgroupName: ownProps.workgroupName,
    automaticallyAdd: ownProps.automaticallyAdd,
  };
};

const mapDispatchToProps: MapDispatchToProps<EditUserActions, EditUserContainerProps> = (
  dispatch,
  ownProps
) => {
  return {
    fetchUser: (userId: number) => dispatch<any>(fetchUser(userId)),
    saveUser: (user: UserDto, _, addToWorkgroup?: boolean) => {
      dispatch<any>(saveUser(user, ownProps.workgroupId, ownProps.bladeId, addToWorkgroup));
      dispatch<any>(userEditingFinished());
    },
    fetchEmailTemplates: () => dispatch<any>(fetchWelcomeEmailTemplates()),
    closeBlade: () => {
      dispatch<any>(BladeActions.forceCloseBlade(EditUserBladeType));
      dispatch<any>(userEditingFinished());
    },
  };
};

export const EditUserBladeType = 'EDIT_USER_BLADE';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 500 },
  bladeType: EditUserBladeType,
  id: (_: any) => EditUserBladeType,
  title: (ownProps: EditUserContainerProps) =>
    ownProps.userId > 0 ? `${ownProps.firstName} ${ownProps.lastName}` : 'Create User',
  allowMultipleInstances: false,
};

export const EditUserContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(EditUser);
