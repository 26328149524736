import { TextFastFieldWrapper } from 'app/components/forms';
import { IdName } from 'app/dataStore/types';
import { LocationSearchFields } from 'app/newnity/duck/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from './contentWrapper';

export interface LocationTabProps {
  handleChange(e: React.ChangeEvent): void;
  companies: IdName[];
}

export const LocationTab = (props: LocationSearchFields & LocationTabProps) => {
  const [t] = useTranslation();

  return (
    <ContentWrapper>
      <TextFastFieldWrapper
        name="location.locationNumber"
        value={props.locationNumber}
        onChange={props.handleChange}
        label={t('newnity.search.locationNumber')}
      />
      <TextFastFieldWrapper
        name="location.locationName"
        value={props.locationName}
        onChange={props.handleChange}
        label={t('newnity.search.locationName')}
      />
      <TextFastFieldWrapper
        name="location.deviceNumber"
        value={props.deviceNumber}
        onChange={props.handleChange}
        label={t('newnity.search.deviceNumber')}
      />
      <TextFastFieldWrapper
        name="location.serial"
        value={props.serial}
        onChange={props.handleChange}
        label={t('newnity.search.serial')}
      />
      <TextFastFieldWrapper
        name="location.siteNumber"
        value={props.siteNumber}
        onChange={props.handleChange}
        label={t('newnity.search.siteNumber')}
      />
      <TextFastFieldWrapper
        name="location.company"
        defaultValue={props.company}
        onChange={props.handleChange}
        label={t('newnity.search.company')}
      />
    </ContentWrapper>
  );
};
