import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextFastFieldWrapper } from 'app/components/forms';
import { ContentWrapper } from './contentWrapper';

export interface CompanyTabProps {
  handleChange(e: React.ChangeEvent): void;
}

export interface CompanyTabFields {
  name: string;
  number: string;
}

export const CompanyTab = (props: CompanyTabFields & CompanyTabProps) => {
  const [t] = useTranslation();

  return (
    <ContentWrapper>
      <TextFastFieldWrapper
        name="company.number"
        value={props.number}
        onChange={props.handleChange}
        label={t('newnity.search.companyNumber')}
      />
      <TextFastFieldWrapper
        name="company.name"
        value={props.name}
        onChange={props.handleChange}
        label={t('newnity.search.companyName')}
      />
    </ContentWrapper>
  );
};
