import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl } from '@material-ui/core';

import { TextFastFieldWrapper } from 'app/components/forms';
import { Field, FieldProps } from 'formik';
import { Autocomplete } from '../../../../components/autocomplete';
import { countries } from '../../../../dataStore/countries';
import { CountryInfo } from '../../../../dataStore/types';
import { ContentWrapper } from './contentWrapper';
import { useStyles } from './tabs.jss';

export interface AddressTabProps {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  handleChange(e: React.ChangeEvent): void;
  key: string;
  parentName: string;
}

export const AddressTab = (props: AddressTabProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <ContentWrapper>
      <TextFastFieldWrapper
        name={`${props.parentName}.address`}
        value={props.address}
        onChange={props.handleChange}
        label={t('newnity.search.address')}
        key={`${props.key}-address`}
      />
      <TextFastFieldWrapper
        name={`${props.parentName}.city`}
        value={props.city}
        onChange={props.handleChange}
        label={t('newnity.search.city')}
        key={`${props.key}-city`}
      />
      <TextFastFieldWrapper
        name={`${props.parentName}.state`}
        value={props.state}
        onChange={props.handleChange}
        label={t('newnity.search.state')}
        key={`${props.key}-state`}
      />
      <TextFastFieldWrapper
        name={`${props.parentName}.zip`}
        value={props.zip}
        onChange={props.handleChange}
        label={t('newnity.search.zip')}
        key={`${props.key}-zip`}
      />
      <FormControl className={classes.selectMargin}>
        <Field name={`${props.parentName}.country`}>
          {({ form }: FieldProps) => (
            <Autocomplete
              items={countries}
              selectedItem={props.country}
              minCharInput={2}
              textFieldProps={{ name: 'something', label: t('newnity.search.country') }}
              onChange={(item: CountryInfo | undefined) =>
                form.setFieldValue(`${props.parentName}.country`, item ? item.name : '')
              }
              key={`${props.key}-country`}
            />
          )}
        </Field>
      </FormControl>
    </ContentWrapper>
  );
};
