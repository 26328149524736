import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextFastFieldWrapper } from 'app/components/forms';
import { ContentWrapper } from './contentWrapper';

export interface PhoneTabProps {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  handleChange(e: React.ChangeEvent): void;
}

export const PhoneTab = (props: PhoneTabProps) => {
  const [t] = useTranslation();

  return (
    <ContentWrapper>
      <TextFastFieldWrapper
        name="company.phone"
        value={props.phone}
        onChange={props.handleChange}
        label={t('newnity.search.phone')}
      />
      <TextFastFieldWrapper
        name="company.email"
        value={props.email}
        onChange={props.handleChange}
        label={t('newnity.search.email')}
      />
      <TextFastFieldWrapper
        name="company.firstName"
        value={props.firstName}
        onChange={props.handleChange}
        label={t('newnity.search.firstName')}
      />
      <TextFastFieldWrapper
        name="company.lastName"
        value={props.lastName}
        onChange={props.handleChange}
        label={t('newnity.search.lastName')}
      />
    </ContentWrapper>
  );
};
