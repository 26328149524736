import React from 'react';

import { LocationListBladeType } from '../../..';
import { DataGridContainer, DataGridSelectionType } from '../../../../components/dataGrid';
import { EntityType, IdName } from '../../../../dataStore';
import { NDeviceSearchResult, NLocationSearchResult } from '../../../../dataStore/types';
import { DeviceBladeType } from '../../device/edit/deviceContainer';
import { DeviceListBladeType } from '../../device/list/deviceListContainer';
import { LocationEditBladeType } from '../../location/edit/locationEditContainer';
import { Loading } from './loading';
import { NoResults } from './noresults';
import { Placeholder } from './placeholder';
import { useStyles } from './results.jss';

export interface ResultsActions {
  enterCompanyMode: (id: number) => void;
  openCompanyEditBlade: (id: number, name: string) => void;
  openBlade: (parent: string, bladeType: string, props: object) => void;
  setSelectedLocations: (items: IdName[]) => void;
  setSelectedDevices: (items: IdName[]) => void;
}

export interface ResultsProps {
  searchedEntity?: EntityType;
  isFetching: boolean;
  items: IdName[];
}

const columnSettings = {
  [EntityType.NCompanySearchResult]: [
    { name: 'number', width: 200 },
    { name: 'name', width: 190, sort: { order: 0, direction: 'asc' }, lockVisibility: true},
    { name: 'city', width: 150 },
    { name: 'country', width: 150 },
  ],
  [EntityType.NLocationSearchResult]: [
    { name: 'locationNumber', width: 220 },
    { name: 'locationName', width: 190, lockVisibility: true, sort: { order: 0, direction: 'asc' } },
    { name: 'siteNumber', width: 150 },
    { name: 'companyName', width: 150 },
  ],
  [EntityType.NDeviceSearchResult]: [
    { name: 'id', width: 160 },
    { name: 'serial', width: 180, lockVisibility: true, sort: { order: 0, direction: 'asc' } },
    { name: 'salesOrder', width: 190 },
    { name: 'deviceDescription', width: 180 },
    { name: 'locationName', width: 180 },
    { name: 'locationNumber', width: 180 },
    { name: 'siteNumber', width: 140 },
    { name: 'companyName', width: 150 },
  ],
};

export const Results = (props: ResultsProps & ResultsActions) => {
  const classes = useStyles();

  const onSelect = (item: IdName) => {
    switch (props.searchedEntity) {
      case EntityType.NCompanySearchResult:
        props.enterCompanyMode(item.id);
        props.openCompanyEditBlade(item.id, item.name);
        break;
      case EntityType.NLocationSearchResult:
        const locationItem = item as NLocationSearchResult;
        props.enterCompanyMode(locationItem.companyId);
        props.openBlade('', LocationListBladeType, {});
        props.setSelectedLocations([{ id: locationItem.id, name: locationItem.locationName }]);
        props.openBlade(LocationListBladeType, LocationEditBladeType, {
          locationSiteId: locationItem.id,
          locationName: locationItem.locationName,
        });
        break;
      case EntityType.NDeviceSearchResult:
        const deviceItem = item as NDeviceSearchResult;
        props.enterCompanyMode(deviceItem.companyId);
        props.openBlade('', DeviceListBladeType, {});
        props.setSelectedDevices([{ id: deviceItem.id, name: deviceItem.id.toString() }]);
        props.openBlade(DeviceListBladeType, DeviceBladeType, {
          deviceId: deviceItem.id,
        });
        break;
      default:
        break;
    }
  };

  const content = () => {
    if (props.searchedEntity === undefined) {
      return <Placeholder />;
    }

    if (props.isFetching) {
      return <Loading />;
    }

    if (props.items.length === 0) {
      return <NoResults />;
    }

    return (
      <div className={classes.gridContainer}>
        <DataGridContainer
          entityType={props.searchedEntity}
          items={props.items}
          onSelect={items => onSelect(items[0])}
          onRowClicked={item => onSelect(item)}
          selectionType={DataGridSelectionType.None}
          showSelectionColumn={false}
          // @ts-ignore
          columnSettings={columnSettings[props.searchedEntity]}
        />
      </div>
    );
  };

  return <div className={classes.resultsContainer}>{content()}</div>;
};
