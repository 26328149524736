import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ButtonsHeaderAndContent,
  createBtn,
  deleteBtn,
  editBtn,
  customBtn,
} from 'app/components/bladeLayout/buttonsHeaderAndContent';
import { ColumnSettings } from 'app/components/dataGrid/agDataGrid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../localization/i18n';
import { DataGridContainer, DataGridSelectionType } from '../../../../components/dataGrid';
import { DeleteConfirmDialog, useDeleteConfirm } from '../../../../components/deleteConfirmDialog';
import { EntityType, IdName, User } from '../../../../dataStore';
import { Loading } from '../../../../newnity/blades/search/results/loading';
import { useStyles } from './workgroupUsersList.jss';

export interface WorkgroupUsersListProps {
  workgroupId: number;
  users: User[];
  removingUser: boolean;
  isFetching: boolean;
  isEditing: boolean;
}

export interface WorkgroupUsersListActions {
  fetchUsers: () => void;
  removeUsers: (userIds: number[]) => void;
  openNewUserBlade: () => void;
  openUsersList: () => void;
  openEditUser: (userId: number, firstName: string, lastName: string) => void;
}

type Props = WorkgroupUsersListActions & WorkgroupUsersListProps;

const allColumns: ColumnSettings[] = [
  { name: 'id', width: 140 },
  {
    name: 'login',
    width: 150,
    sort: {
      direction: 'asc',
      order: 0,
    },
    lockVisibility: true,
  },
  { name: 'email', width: 170 },
  { name: 'firstName', width: 150 },
  { name: 'lastName', width: 150 },
  { name: 'passwordExpDate', width: 170 },
  {
    name: 'lockLevel',
    width: 140,
    filter: 'agSetColumnFilter',
    formatter: (item: ICellRendererParams) => {
      const data = item.data as User;
      let cellValue = '';
      switch (data.lockLevel) {
        case 0:
          cellValue = i18n.t('workgroup.users.isLocked.unlocked');
          break;
        case 1:
          cellValue = i18n.t('workgroup.users.isLocked.locked');
          break;
        case 2:
          cellValue = i18n.t('workgroup.users.isLocked.masterlocked');
          break;
        case 3:
          cellValue = i18n.t('workgroup.users.isLocked.inactivity');
          break;
      }
      return <div>{cellValue}</div>;
    },
    keyCreator: (params: any) => {
      switch (params.value) {
        case 0:
          return i18n.t('workgroup.users.isLocked.unlocked');
        case 1:
          return i18n.t('workgroup.users.isLocked.locked');
        case 2:
          return i18n.t('workgroup.users.isLocked.masterlocked');
        case 3:
          return i18n.t('workgroup.users.isLocked.inactivity');
      }
      return 'Not Set';
    },
  },
  {
    name: 'lockReason',
    width: 150,
  },
];

const userRemoveMessage = (selected: User[], propsUsers: User[], isEditing: boolean) => {
  if (selected.length > 1) {
    return i18n.t('workgroup.users.remove.confirm.generic', { count: selected.length });
  } else {
    const userLogin = propsUsers.find(user => selected[0].id === user.id);
    let removeMessage = '';
    if (isEditing) {
      removeMessage = removeMessage + i18n.t('workgroup.users.remove.confirm.editingUser');
    }
    if (userLogin) {
      removeMessage =
        removeMessage +
        i18n.t('workgroup.users.remove.confirm.message', { userLogin: userLogin.login });
    }
    return removeMessage;
  }
};

export const WorkgroupUserList: React.FunctionComponent<Props> = props => {
  const [removeConfirmUsers, setRemoveConfirmUsers] = useDeleteConfirm();
  const { fetchUsers } = props;

  const [selected, setSelected] = useState<User[]>([]);
  const classes = useStyles();
  const [t] = useTranslation();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onSelect = (users: IdName[]) => {
    setSelected(users as User[]);
  };

  const onUserRemove = () => {
    props.removeUsers(removeConfirmUsers.id as number[]);
    setRemoveConfirmUsers({
      id: selected.map((user: User) => user.id),
      name: '',
      confirmNeeded: false,
    });
  };

  const onEditUserClick = () => {
    if (!selected.length) {
      return;
    }

    const userId = selected[0].id;
    const firstName = props.users.filter((user: User) => user.id === userId)[0].firstName;
    const lastName = props.users.filter((user: User) => user.id === userId)[0].lastName;
    props.openEditUser(selected[0].id, firstName, lastName);
  };

  const onRemoveUsersClick = () => {
    if (selected.length > 0) {
      setRemoveConfirmUsers({
        id: selected.map((user: User) => user.id),
        name: selected.map((user: User) => user.email).join(','),
        confirmNeeded: true,
      });
    }
  };

  // Logic is, if more than one user is selected, set disable = true
  const disableEdit = selected.length > 1;
  const buttons = [
    createBtn(props.openNewUserBlade, t('create')),
    customBtn(t('add'), props.openUsersList, 'add'),
    editBtn(onEditUserClick, t('edit'), disableEdit),
    deleteBtn(onRemoveUsersClick, t('remove')),
  ];

  return (
    <ButtonsHeaderAndContent
      isBusy={props.removingUser}
      buttons={buttons}
      removePadding
      removeVerticalScroll
    >
      {props.isFetching ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <DataGridContainer
            entityType={EntityType.User}
            onSelect={onSelect}
            defaultSelection={selected}
            items={props.users}
            selectionType={DataGridSelectionType.Multiple}
            columnSettings={allColumns}
          />
          <DeleteConfirmDialog
            important={false}
            title={t('workgroup.users.remove.confirm.title')}
            message={
              selected.length > 0 ? userRemoveMessage(selected, props.users, props.isEditing) : ''
            }
            open={removeConfirmUsers.confirmNeeded}
            onDelete={onUserRemove}
            onCancel={() =>
              setRemoveConfirmUsers({
                id: 0,
                name: '',
                confirmNeeded: false,
              })
            }
            deleteLabel={t('workgroup.users.dialog.remove')}
          />
        </div>
      )}
    </ButtonsHeaderAndContent>
  );
};
