import { BladeActions } from 'app/blade';
import { pushNotification } from 'app/components/notifier';
import { DataStoreActions, EntityType } from 'app/dataStore';
import { entityUpsert } from 'app/dataStore/actions';
import { User } from 'app/dataStore/types';
import { ActionTypes, UserDto, UsersState } from 'app/users/duck/types';
import { WorkgroupApi } from 'app/workgroup';
import { Dispatch } from 'react';
import { action } from 'typesafe-actions';

import { closeChildrenBlades } from 'app/blade/duck/actions';
import { UserApi } from './api';

export function fetchWorkgroupUsers(workgroupId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(workgroupUserListRequest(workgroupId));
    try {
      const users = await WorkgroupApi.getUsersForWorkgroup(workgroupId);
      dispatch(workgroupUserListSuccess(workgroupId, users));
    } catch (err) {
      dispatch(workgroupUserListError(workgroupId, err));
      dispatch(pushNotification(`Could not fetch users for workgroup: ${workgroupId}`, 'error'));
    }
  };
}

export function removeUsersFromWorkgroup(workgroupId: number, userIds: number[], bladeId: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(workgroupRemoveUsersRequest(workgroupId, userIds));
    try {
      await WorkgroupApi.removeUsersFromWorkgroup(workgroupId, userIds);
      dispatch(workgroupRemoveUsersSuccess(workgroupId, userIds));
      dispatch(closeChildrenBlades(bladeId));
    } catch (err) {
      dispatch(workgroupRemoveUsersError(workgroupId, userIds, err));
      dispatch(
        pushNotification(`Error removing ${userIds.length} from workgroup ${workgroupId}`, 'error')
      );
    }
  };
}

export function saveUser(userData: UserDto, workgroupId?: number, bladeId?: string, addToWorkgroup?: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(userSaveRequest(userData.id));
    try {
      const user = await UserApi.saveUser(userData);
      dispatch(userSaveSuccess(user.id));

      if(addToWorkgroup && workgroupId) {
        dispatch(addUsersToWorkgroup(workgroupId, [user.id]));
      }

      // refresh userWorkgroupBlade
      if (userData.id) {
        if (workgroupId) {
          dispatch(fetchWorkgroupUsers(workgroupId));
        }
      }

      // this will show the updated (or new) user in the "global" users list
      dispatch(entityUpsert(EntityType.User, user));

      // close Create/Edit User blade
      if (bladeId) {
        dispatch(BladeActions.forceCloseBlade(bladeId));
      }

      if (userData.id > 0) {
        dispatch(pushNotification(`Updated user ${user.login}`, 'success'));
      } else {
        dispatch(pushNotification(`Created user ${user.login}`, 'success'));
      }
      if(userData.sendWelcomeEmail)
      {
        dispatch(pushNotification('Welcome email sent', 'success'));
      }
    } catch (err) {
      userData.isSaving = false;
      dispatch(userSaveError(err, userData.id));
      dispatch(pushNotification(err, 'error'));
    }
  };
}

export function fetchUser(userId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(userFetchRequest(userId));
    try {
      const user = await UserApi.fetchUser(userId);
      dispatch(entityUpsert(EntityType.User, user));
    } catch (err) {
      dispatch(userFetchError(userId, err));
      dispatch(pushNotification(err, 'error'));
    }
  };
}

export function addUsersToWorkgroup(workgroupId: number, userIds: number[]) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(workgroupAddUsersRequest(workgroupId, userIds));
      await WorkgroupApi.addUsersToWorkgroup(workgroupId, userIds);
      dispatch(workgroupAddUsersSuccess(workgroupId, userIds));
      dispatch(fetchWorkgroupUsers(workgroupId));
    } catch (err) {
      dispatch(workgroupAddUsersSuccess(workgroupId, userIds));
      dispatch(
        pushNotification(`Error removing ${userIds.length} from workgroup ${workgroupId}`, 'error')
      );
    }
  };
}

export function fetchUsers() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.User));
    try {
      const users = await UserApi.getUsers();
      dispatch(DataStoreActions.entityListSuccess(EntityType.User, users));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.User, err));
      dispatch(pushNotification(`Could not fetch users`, 'error'));
    }
  };
}

export const workgroupUserListRequest = (workgroupId: number) =>
  action(ActionTypes.WORKGROUP_USER_LIST_REQUEST, workgroupId);

export const workgroupUserListError = (workgroupId: number, error: string) =>
  action(ActionTypes.WORKGROUP_USER_LIST_ERROR, { workgroupId, error });

export const workgroupUserListSuccess = (workgroupId: number, users: User[]) =>
  action(ActionTypes.WORKGROUP_USER_LIST_SUCCESS, { workgroupId, users });

export const workgroupRemoveUsersRequest = (workgroupId: number, userIds: number[]) =>
  action(ActionTypes.WORKGROUP_REMOVE_USERS_REQUEST, workgroupId);

export const workgroupRemoveUsersError = (workgroupId: number, userIds: number[], error: string) =>
  action(ActionTypes.WORKGROUP_REMOVE_USERS_ERROR, { workgroupId, error, userIds });

export const workgroupRemoveUsersSuccess = (workgroupId: number, userIds: number[]) =>
  action(ActionTypes.WORKGROUP_REMOVE_USERS_SUCCESS, { workgroupId, userIds });

export const userSaveRequest = (userId?: number) => action(ActionTypes.USER_SAVE_REQUEST, userId);

export const userSaveError = (error: string, userId?: number) =>
  action(ActionTypes.USER_SAVE_ERROR, { error, userId });

export const userSaveSuccess = (userId: number) =>
  action(ActionTypes.USER_SAVE_SUCCESS, { userId });

export const userFetchRequest = (userId: number) => action(ActionTypes.USER_FETCH_REQUEST, userId);

export const userFetchError = (userId: number, error: string) =>
  action(ActionTypes.USER_FETCH_ERROR, { userId, error });

export const userFetchSuccess = (user: User) => action(ActionTypes.USER_FETCH_SUCCESS, user);

export const workgroupAddUsersRequest = (workgroupId: number, userIds: number[]) =>
  action(ActionTypes.WORKGROUP_ADD_USERS_REQUEST, workgroupId);

export const workgroupAddUsersError = (workgroupId: number, userIds: number[], error: string) =>
  action(ActionTypes.WORKGROUP_ADD_USERS_ERROR, { workgroupId, error, userIds });

export const workgroupAddUsersSuccess = (workgroupId: number, userIds: number[]) =>
  action(ActionTypes.WORKGROUP_ADD_USERS_SUCCESS, { workgroupId, userIds });

export const workgroupAddUsersSetSaved = (value: boolean) =>
  action(ActionTypes.WORKGROUP_ADD_USERS_SET_SAVED, value);

export const userEditingStarting = () =>
   action(ActionTypes.USER_EDIT_REQUEST)

export const userEditingFinished = () =>
   action(ActionTypes.USER_EDIT_COMPLETED)

export function fetchWelcomeEmailTemplates () {
    return async (dispatch: Dispatch<any>, getState: () => { users: UsersState }) => {
      const state = getState();
      if(!state.users.fetchWelcomeEmailTemplates.fetchComplete){
        try {
          dispatch(getWelcomeEmailsRequest());
          const response = await UserApi.getWelcomeEmailTemplates();
          dispatch(getWelcomeEmailsSuccess(response));
        } catch (err) {
          dispatch(getWelcomeEmailsFailed(err));
          dispatch(pushNotification(`Could not send welcome email: ${err}`, 'error'));
        }
      }
    }
  }
export const getWelcomeEmailsRequest = () => action(ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_REQUESTED);
export const getWelcomeEmailsSuccess = (welcomeEmailTemplatesList: string[]) => action(ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_SUCCESS, {welcomeEmailTemplatesList});
export const getWelcomeEmailsFailed = (err: string) => action(ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_FAILED, err);
