import { User } from '../../dataStore';
import { fetchHelper, apiFetch } from '../../v6/ajax';
import { UserDto } from './types';

export const UserApi = {
  saveUser: async (userSaveObj: UserDto): Promise<User> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/users` +
        (userSaveObj.id > 0 ? `/${userSaveObj.id}` : ``),
      userSaveObj.id > 0 ? 'PUT' : 'POST',
      userSaveObj
    );

    if (!response.ok) {
      throw await response.text();
    }

    return response.json();
  },

  fetchUser: async (userId: number): Promise<User> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/users/${userId}`);

    if (!response.ok) {
      throw await response.text();
    }

    return response.json();
  },

  getUsers: async (): Promise<User[]> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/users`);
    return response.json();
  },

  getWelcomeEmailTemplates: async () => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/users/templates`);
      if (!response.ok) {
        throw await response.text();
      }
    return response.json();
  }
};
