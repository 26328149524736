import { SnackbarProvider } from 'notistack';
import React from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { moodTheme } from './app.jss';
import { BladeRowContainer } from './blade/bladeRow/bladeRowContainer';
import { NotifierContainer } from './components/notifier';
import { Layout } from './layout';

export const App = () => {
  return (
    <ThemeProvider theme={moodTheme}>
      <SnackbarProvider maxSnack={3}>
        <Layout>
          <NotifierContainer />
          <BladeRowContainer />
        </Layout>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
